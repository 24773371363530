.dot {
  animation: typing 1.5s infinite;
  opacity: 0;
  margin: 0 4px;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes typing {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}