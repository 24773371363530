message-item {
  display: flex;
  margin: 8px auto;
}

.message-avatar-container {
  align-self: flex-end;
  margin: 0px 8px;
}

.message-avatar {
  vertical-align: middle;
  border-radius: 50%;

  height: 28px;
  width: 28px;
}
