.page-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.page-header > .content {
  margin-top: 12%;
  text-align: center;
  margin-bottom: 50px;
}
.page-header.page-header-small {
  min-height: 60vh;
  max-height: 440px;
}
.page-header:before {
  background-color: rgba(0, 0, 0, 0.3);
}
.page-header > .container {
  z-index: 2;
  padding-top: 12vh;
  padding-bottom: 40px;
}
.page-header .page-header-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.page-header .content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
}
.page-header footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.page-header .container {
  height: 100%;
  z-index: 1;
  text-align: center;
  position: relative;
}
.page-header .category,
.page-header .description {
  color: #f00;
}
.page-header:after,
.page-header:before {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
}

.clear-filter[filter-color="blue"] {
  background: linear-gradient(
    90deg,
    rgba(44, 44, 44, 0.2),
    rgba(3, 161, 224, 0.6)
  );
}


