@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500 
    focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
  }
}

@layer utilities {
  .custom-gradient {
    background: rgba(44, 44, 44, 0.2);
    background: -webkit-linear-gradient(
      90deg,
      rgba(44, 44, 44, 0.2),
      rgba(3, 161, 224, 0.6)
    );
    background: -o-linear-gradient(
      90deg,
      rgba(44, 44, 44, 0.2),
      rgba(3, 161, 224, 0.6)
    );
    background: -moz-linear-gradient(
      90deg,
      rgba(44, 44, 44, 0.2),
      rgba(3, 161, 224, 0.6)
    );
    background: linear-gradient(
      90deg,
      rgba(44, 44, 44, 0.2),
      rgba(3, 161, 224, 0.6)
    );
  }
  [data-background-color="blue"] {
    background-color: #2ca8ff;
  }
}

.neon-black-box {
  /* background-color: #000; Black background */
  color: #fff; /* White text */
  /* Neon white glow effect */
  box-shadow: 0 0 5px #000, 0 0 15px #ff0000, 0 0 20px #fff, 0 0 30px #fff,
    0 0 40px #fff;
  transition: all 0.3s ease-in-out;
}

.neon-black-box:hover {
  box-shadow: 0 0 20px #fff, 0 0 40px #fff, 0 0 60px #fff, 0 0 80px #fff;
}

@keyframes rotate {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes move {
  to {
    transform: translateY(15px) rotate(45deg);
  }
}

.animate-rotate {
  animation: rotate 1s linear infinite;
}

.animate-move {
  animation: move 0.5s linear infinite alternate-reverse;
}

.swiper_hero {
  height: 90vh;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .swiper_hero {
    height: 40vh;
    width: 100%;
    margin-bottom: 20px !important;
  }
}

.blur {
  filter: brightness(50%);
  object-fit: cover;
  background-position: "center";
  background-size: "cover";
  width: 100%;
}
/* // bell ringing */
@keyframes wiggle {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
}

.animate-wiggle {
  animation: wiggle 0.5s ease-in-out infinite;
  color: #fff;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
